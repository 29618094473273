import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import packageJson from '../../package.json';


const bugsnagClient = Bugsnag.start({
    apiKey: '0ea2f83c0adff763a68ce56d53db2446',
    plugins: [new BugsnagPluginReact()],
    appVersion: packageJson.version,
    releaseStage: process.env.NODE_ENV
});

export default bugsnagClient;
