import React from 'react';
import {connect} from 'react-redux';
import {Navigate, Outlet, useLocation} from 'react-router';
import PATHS from "../helpers/paths";

// class PrivateRouteOld extends Component {
//     render() {
//         const {component: Component, authenticated, path, ...rest} = this.props;
//         return (
//             <Route path={path} {...rest} render={props => (
//                 authenticated
//                     ? <Component {...rest} />
//                     : <Navigate to={{
//                         pathname: PATHS.LOGIN,
//                         state: {from: props.location}
//                     }}/>
//             )}/>
//         )
//     }
// }

const PrivateRoute = (props) => {
    const {authenticated} = props;
    const location = useLocation();

    return authenticated ? <Outlet/> : <Navigate to={{
        pathname: PATHS.LOGIN,
        state: {from: location}
    }}/>;
}


function mapStateToProps({user}) {
    const authenticated = user.username !== null;

    return {
        authenticated
    }
}

export default connect(mapStateToProps)(PrivateRoute);
