import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {store, persistor} from './store';
import {PersistGate} from 'redux-persist/integration/react';

import bugsnagClient from 'lib/bugsnag';


const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </ErrorBoundary>
    , document.getElementById('root'));

serviceWorker.unregister();
