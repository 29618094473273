//To display error in console only if env is dev
export const consoleError = (...error) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.error(...error);
    }
};

//To display log in console only if env is dev
export const consoleLog = (...message) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(...message);
    }
};