//Groups constants
const Groups = Object.freeze({
    ADMIN: "Admin",
    ACCOUNT_ADMIN: "Account Admin",
    CREW_LEADER: "Crew Leader",
    GROWER: "Grower",
    EMPLOYEE: "Employee",
    PAYROLL: "Payroll",
    SUPERVISOR: "Supervisor",

    //To get group name by int
    getByInt: _int => {
        switch (_int) {
            case 1:
                return Groups.ADMIN;
            case 2:
                return Groups.ACCOUNT_ADMIN;
            case 3:
                return Groups.CREW_LEADER;
            case 4:
                return Groups.GROWER;
            case 5:
                return Groups.EMPLOYEE;
            case 6:
                return Groups.PAYROLL;
            case 7:
                return Groups.SUPERVISOR;
            default:
                return Groups.CREW_LEADER;
        }
    },

    //To get group int by name
    getByName: name => {
        switch (name) {
            case Groups.ADMIN:
                return 1;
            case Groups.ACCOUNT_ADMIN:
                return 2;
            case Groups.CREW_LEADER:
                return 3;
            case Groups.GROWER:
                return 4;
            case Groups.EMPLOYEE:
                return 5;
            case Groups.PAYROLL:
                return 6;
            case Groups.SUPERVISOR:
                return 7;
            default:
                return 3;
        }
    },

    //Helper function to create new user with groups without admin
    createUserGroups: () => {
        return [
            {value: Groups.getByName(Groups.ACCOUNT_ADMIN), label: Groups.ACCOUNT_ADMIN},
            {value: Groups.getByName(Groups.CREW_LEADER), label: Groups.CREW_LEADER},
            {value: Groups.getByName(Groups.GROWER), label: Groups.GROWER},
            {value: Groups.getByName(Groups.EMPLOYEE), label: Groups.EMPLOYEE},
            {value: Groups.getByName(Groups.PAYROLL), label: Groups.PAYROLL},
            {value: Groups.getByName(Groups.SUPERVISOR), label: Groups.SUPERVISOR},
        ];
    },

    //To check if user is admin or not
    isAdmin: groupIDs => {
        let _isAdmin = false;
        groupIDs.map(groupID => {
            if (!_isAdmin && Groups.getByInt(groupID) === Groups.ADMIN) {
                _isAdmin = true;
            }
            return true;
        });
        return _isAdmin;
    },

    /*
     * Permission handlers ==
     *
     * The reason of having permission checks here is to handle it from one place instead of having it spread out at
     * multiple places. Because if there is change of groups or permission, it will be painful to update code and not
     * miss a single place!
     * */

    //To check if user can create new user in system
    hasUserCreatePermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN || Groups.getByInt(groupID) === Groups.ACCOUNT_ADMIN) {
            permission = true;
        }
        return permission;
    },

    //To check if user can update an existing user in system
    hasUserUpdatePermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN || Groups.getByInt(groupID) === Groups.ACCOUNT_ADMIN) {
            permission = true;
        }
        return permission;
    },

    //To check if user can update an existing user in system
    hasUserViewPermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN || Groups.getByInt(groupID) === Groups.ACCOUNT_ADMIN || Groups.getByInt(groupID) === Groups.CREW_LEADER) {
            permission = true;
        }
        return permission;
    },

    //To check if user can add account to an existing user in system
    hasUserAddAccountPermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN || Groups.getByInt(groupID) === Groups.ACCOUNT_ADMIN) {
            permission = true;
        }
        return permission;
    },

    //To check if user can create new account in system
    hasAccountCreatePermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN) {
            permission = true;
        }
        return permission;
    },

    //To check if user can create new Crew in system
    hasCrewCreatePermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN || Groups.getByInt(groupID) === Groups.ACCOUNT_ADMIN) {
            permission = true;
        }
        return permission;
    },

    //To check if user can update an existing Crew in system
    hasCrewUpdatePermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN || Groups.getByInt(groupID) === Groups.ACCOUNT_ADMIN) {
            permission = true;
        }
        return permission;
    },

    //To check if user can update an existing Crew in system
    hasCrewViewPermission: groupID => {
        let permission = false;
        if (Groups.getByInt(groupID) === Groups.ADMIN || Groups.getByInt(groupID) === Groups.ACCOUNT_ADMIN || Groups.getByInt(groupID) === Groups.CREW_LEADER) {
            permission = true;
        }
        return permission;
    },

    //To check if user has permission to manage device settings
    hasDeviceSettingsManagementPermission: groupID => {
        return Groups.getByInt(groupID) === Groups.ADMIN;
    },

    //To check if user is admin and can manage Reports X
    hasReportsXManagementPermission: groupID => {
        return Groups.getByInt(groupID) === Groups.ADMIN;
    },

    //To check if user is not admin and can view ReportsX menu!
    hasReportsXViewPermission: groupID => {
        return Groups.getByInt(groupID) !== Groups.ADMIN;
    },

    //To check if user is supervisor and has specific access to reports
    isSupervisorUser: groupID => {
        return Groups.getByInt(groupID) === Groups.SUPERVISOR;
    },

});

export default Groups;
