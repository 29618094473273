import axios from 'axios';
import {consoleError} from "./consoleLogger";
import {CSRF_COOKIE_NAME} from "./consts";

let tz = "UTC";

try {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (e) {
    consoleError(e);
}

const API = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 60000,
    withCredentials: true,
    xsrfCookieName: CSRF_COOKIE_NAME,
    xsrfHeaderName: "X-CSRFToken",
    headers: {'X-hpay-auth': 'HarvestPayWeb', 'X-tz': tz}
});

// ----------------------------------------------------------------------------------------------------------
// Axios interceptors to handler success and errors other functionality
// starts here ----------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------


//Handle request
const requestConfigHandler = async config => {
    // Checking url and checking if it contains `undefined` in it. If it does, we will reject the promise with no
    // error as wr don't want to process the request. This is a dev case scenario but it can happen when user is
    // logged in and script has not made connection with `checkpoint` to get all the routes.
    if (config.url.toString().toLowerCase().includes("undefined")) {
        return Promise.reject({});
    }

    const initialized = localStorage.getItem('initialized').toString().toLowerCase() === "true";

    if (!config.url.toString().toLowerCase().includes("checkpoint") && !initialized) {
        await new Promise(resolve => setTimeout(resolve, 2000));
    }

    //Returning config if validation passes.
    return config;
};

//Handle request error
const requestErrorHandler = (error) => {
    return Promise.reject({...error});
};


//Handle error response
const responseErrorHandler = (error) => {
    // Handling error from network call using Axios interceptor and error handler. If HTTP status is 403 means user
    // is not authenticated and so we will check for more details in it like response data code and response data
    // message to check if it is custom 403 from server or not. If it is, simply redirect user to login screen and in
    // HarvestPay case, its home page!

    if (error?.response?.status === 403) {
        if (
            error.response.data.code !== undefined && error.response.data.code === 403 &&
            error.response.data.data.message !== undefined && error.response.data.data.message === "Authentication required!") {
            window.localStorage.clear();
            window.location.replace("/?_rd=true&_er=auth_failed&_code=403");
        }
    } else {
        return Promise.reject({...error});
    }

};

//Handle successful response
const responseSuccessHandler = (response) => {
    return response
};

API.interceptors.request.use(
    config => requestConfigHandler(config),
    error => requestErrorHandler(error)
);

API.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);

// ----------------------------------------------------------------------------------------------------------
// Axios interceptors to handler success and errors other functionality
// ends here ------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------


const _CHECKPOINT = '/checkpoint';  //Endpoint for Checkpoint

//Exporting default Axios to use for connections
export default API;

//Exporting other constants
export const CHECKPOINT = _CHECKPOINT;
