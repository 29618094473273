//Frontend paths mainly to be used in redirecting user from one place to another
const PATHS = {
    HOME: "/",
    DASHBOARD: "/dashboard",

    //Account related links
    REGISTER: "/account/register",
    LOGIN: "/account/login",
    LOGOUT: "/account/logout",

    //System links
    SYSTEM_ADD_USER: "/system/AddUser",
    SYSTEM_MANAGE_USERS: "/system/ManageUsers",
    SYSTEM_ADD_MULTIPLE_USERS: "/system/AddMultipleUsers",
    SYSTEM_VIEW_USER: "/system/ViewUser",

    //Entity links
    ENTITY_ADD_ACCOUNT: "/entity/AddAccount",
    ENTITY_ADD_CREW: "/entity/AddCrew",
    ENTITY_MANAGE_CREWS: "/entity/ManageCrews",

    ENTITY_ADD_DEVICES: "/entity/AddDevices",
    ENTITY_VIEW_DEVICES: "/entity/ViewDevices",
    ENTITY_ADD_DEVICE_SETTINGS: "/entity/AddDeviceSettings",
    ENTITY_VIEW_CONFIGURATION_PROFILES: "/entity/ViewConfigurationProfiles",
    ENTITY_VIEW_DEVICE_SETTINGS: "/entity/ViewDeviceSettings/:sid",
    ENTITY_VIEW_DEVICE_SETTINGS_HISTORY: "/entity/ViewDeviceSettingsHistory/:sid",
    ENTITY_VIEW_DEVICE_SETTINGS_HISTORY_BY_ID: "/entity/ViewDeviceSettingsHistory/:sid/:sh_id",
    ENTITY_EDIT_DEVICE_SETTINGS: "/entity/EditDeviceSettings/:sid",
    ENTITY_EDIT_CONFIG_SETTINGS: "/entity/EditConfigSettings/:config_id",

    REPORTS: "/reports",
    REPORTS_WORK_LOG: "/reports/WorkLog",

    REPORTSX_MANAGE_USER_ACCESS: "/ReportsX/ManageUserAccess",
    REPORTSX_MANAGE_SUPERVISORS: "/ReportsX/ManageSupervisors",
    REPORTSX_MANAGE_WORK_DETAILS_ACCESS: "/ReportsX/ManageWorkDetailsAccess",
    REPORTSX_SUPERVISOR_MANAGE_IWL: "/ReportsX/Supervisor/ManageIgnoreWorkerList",
    REPORTSX_REPORT: "/ReportsX/Report/:report_key",

    // dynamic path
    DYNAMIC_VIEW_DEVICE_SETTINGS: "/entity/ViewDeviceSettings",
    DYNAMIC_VIEW_DEVICE_SETTINGS_HISTORY: "/entity/ViewDeviceSettingsHistory",
    DYNAMIC_EDIT_DEVICE_SETTINGS: "/entity/EditDeviceSettings",
    DYNAMIC_EDIT_CONFIG_SETTINGS: "/entity/EditConfigSettings",
    DYNAMIC_REPORTSX_REPORT: "/ReportsX/Report",

    //To get key of path which is used in navigation menu
    getKeyOfPath: path => {
        switch (path) {
            case PATHS.DASHBOARD:
                return "100";
            case PATHS.LOGOUT:
                return "101";
            case PATHS.SYSTEM_ADD_USER:
                return "200";
            case PATHS.SYSTEM_MANAGE_USERS:
                return "201";
            case PATHS.SYSTEM_ADD_MULTIPLE_USERS:
                return "210";
            case PATHS.SYSTEM_VIEW_USER:
                return "220";
            case PATHS.ENTITY_ADD_ACCOUNT:
                return "300";
            case PATHS.ENTITY_ADD_CREW:
                return "410";
            case PATHS.ENTITY_MANAGE_CREWS:
                return "420";
            case PATHS.ENTITY_ADD_DEVICES:
                return "430";
            case PATHS.ENTITY_VIEW_DEVICES:
                return "440";
            case PATHS.ENTITY_ADD_DEVICE_SETTINGS:
                return "450";
            case PATHS.ENTITY_VIEW_DEVICE_SETTINGS:
                return "460";
            case PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES:
                return "470";
            case PATHS.REPORTS:
                return "510";
            case PATHS.REPORTS_WORK_LOG:
                return "520";
            case PATHS.REPORTSX_MANAGE_USER_ACCESS:
                return "550";
            case PATHS.REPORTSX_MANAGE_SUPERVISORS:
                return "551";
            case PATHS.REPORTSX_SUPERVISOR_MANAGE_IWL:
                return "552";
            case PATHS.REPORTSX_MANAGE_WORK_DETAILS_ACCESS:
                return "553";
            case PATHS.REPORTSX_REPORT:
                return "551";
            default:
                return "0";
        }
    },

    //To get key of submenu which is used in navigation menu
    getKeyOfSubMenu: path => {
        switch (path) {
            case PATHS.SYSTEM_ADD_USER:
                return "2110";
            case PATHS.SYSTEM_MANAGE_USERS:
                return "2110";
            case PATHS.SYSTEM_ADD_MULTIPLE_USERS:
                return "2110";
            case PATHS.SYSTEM_VIEW_USER:
                return "2110";
            case PATHS.ENTITY_ADD_ACCOUNT:
                return "3110";
            case PATHS.ENTITY_ADD_CREW:
                return "4110";
            case PATHS.ENTITY_MANAGE_CREWS:
                return "4110";
            case PATHS.ENTITY_ADD_DEVICE_SETTINGS:
                return "4210";
            case PATHS.ENTITY_ADD_DEVICES:
                return "4210";
            case PATHS.ENTITY_VIEW_DEVICES:
                return "4210";
            case PATHS.ENTITY_VIEW_DEVICE_SETTINGS:
                return "4210";
            case PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES:
                return "4210";
            case PATHS.REPORTS:
                return "5110";
            case PATHS.REPORTSX_REPORT:
                return "5510";
            default:
                return "0";
        }
    },

    generateDynamicPath: (pathKey, pathParams) => {
        return `${pathKey}/${pathParams.join("/")}`;
    },
};

export default PATHS;
