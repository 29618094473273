export const CSRF_COOKIE_NAME = "csrftoken";
export const WEEK_DAYS = [
    {value: "sunday", name: "Sunday"},
    {value: "monday", name: "Monday"},
    {value: "tuesday", name: "Tuesday"},
    {value: "wednesday", name: "Wednesday"},
    {value: "thursday", name: "Thursday"},
    {value: "friday", name: "Friday"},
    {value: "saturday", name: "Saturday"},
];

export const DEFAULT_HOURS_OFFERED = () => {
    let start = 0.00;
    let _list = [start];
    while (start !== 24.00) {
        start += 0.25;
        _list.push(start);
    }

    return _list;
};