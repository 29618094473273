import React, {Component, Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {connect} from 'react-redux';
import './assets/css/MaterializeSelectPicks.css';
import './assets/css/common.css';
import {consoleError} from "./helpers/consoleLogger";
import API, {CHECKPOINT} from './helpers/api';
import {setRoutesState} from "./actions/routes";
import bugsnag from "./lib/bugsnag";
import logo from "../src/assets/imgs/hpaylogo.png";
import SideNavigation from "./components/common/SideNavigation";
import LazyLoadingComponentPlaceholder from "./components/common/LazyLoadingComponentPlaceholder";
import Pages from "./components/Pages";
import {Row, Modal, Layout} from 'antd';
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";

const {Header, Content} = Layout;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {shouldMount: false};
        localStorage.setItem('initialized', "false");
        this.__init__().catch(e => consoleError("this.__init__() ==> ", e));
    };

    componentDidUpdate = () => {
        if (this.props.routes !== undefined
            && this.props.routes !== null
            && this.props.routes.checkpoint !== undefined
            && this.props.routes.checkpoint !== null
            && !this.state.shouldMount) {
            this.setState(prevState => ({
                ...prevState,
                shouldMount: true
            }));
        }
    };

    __init__ = async () => {
        let _this = this;

        API.get(CHECKPOINT, {timeout: 10000})
            .then(resp => {
                try {
                    if (resp.status === 200 && !resp.data.error) {
                        _this.props.updateRoutes(resp.data.routes);
                    } else {
                        this._displayErrorModal();
                    }
                } catch (e) {
                    bugsnag.notify(e);
                    this._displayErrorModal();
                }
            })
            .catch(err => {
                console.error(err);
                bugsnag.notify(err.toString());
                this._displayErrorModal();
            });
    };

    _displayErrorModal = () => {
        Modal.error({
            title: 'Error',
            content: 'There was some processing error!',
        });
    };

    render() {
        return (
            <Router>
                {this.state.shouldMount
                    ?
                    <div className="bg_fabric">
                        <Layout style={{minHeight: '100vh', overflowY: 'auto', overFlowX: 'auto'}}>
                            <Header className="orange lighten-1">
                                <Row justify="center" type="flex">
                                    <img alt="HarvestPay" title="HarvestPay" src={logo} height="95"/>
                                </Row>
                            </Header>
                            <Layout>
                                {this.props.authenticated && <SideNavigation/>}
                                <Content>
                                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                                        <Pages/>
                                    </Suspense>
                                </Content>
                            </Layout>
                        </Layout>
                    </div>
                    :
                    <Row style={{height: '100vh'}} align="middle" justify="center">
                        <LoadingOutlined style={{fontSize: '2rem'}}/>
                    </Row>
                }
            </Router>
        );
    }
}


function mapStateToProps({user, routes}) {
    const authenticated = user.username !== null;
    return {
        user, routes, authenticated
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateRoutes: data => {
            dispatch(setRoutesState(data));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
