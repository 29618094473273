import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {Layout, Menu, Row, Col, Divider} from 'antd';
import {
    HomeOutlined,
    LogoutOutlined,
    UsergroupAddOutlined,
    UserAddOutlined,
    ContactsOutlined,
    UserOutlined,
    PlusOutlined,
    ProfileOutlined,
    TabletOutlined,
    SettingOutlined,
    AimOutlined
} from "@ant-design/icons";

import Groups from "../../lib/Groups";
import PATHS from "../../helpers/paths";
import API from "../../helpers/api";

const {Sider} = Layout;
const {SubMenu} = Menu;

class SideNavigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            // Users menu related permissions
            hasUserCreatePermission: this._hasUserCreatePermission(),
            hasUserUpdatePermission: this._hasUserUpdatePermission(),
            hasUserViewPermission: this._hasUserViewPermission(),

            // Crews menu related permissions
            hasCrewCreatePermission: this._hasCrewCreatePermission(),
            hasCrewUpdatePermission: this._hasCrewUpdatePermission(),
            hasCrewViewPermission: this._hasCrewViewPermission(),

            hasDeviceSettingsManagementPermission: this._hasDeviceSettingsManagementPermission(),

            hasReportsXManagementPermission: this._hasReportsXManagementPermission(),
            hasReportsXViewPermission: this._hasReportsXViewPermission(),
            isSupervisorUser: this._isSupervisorUser(),
            defaultKeysChecked: false,

            reportsData: null
        };

    }

    componentDidMount() {
        let _this = this;
        API.get(_this.props.routes.reports_x_get_user_active_reports).then(resp => {
            _this.setState(prevState => (
                {
                    ...prevState,
                    reportsData: resp.data.data
                }
            ));
        }).catch(err => {
            console.error(err);
        });
    }

    onCollapse = collapsed => {
        this.setState({collapsed});
    };

    //User menu permission checks : Create, Update and View
    _hasUserCreatePermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasUserCreatePermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _hasUserUpdatePermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasUserUpdatePermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _hasUserViewPermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasUserViewPermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    //Crew menu permission checks : Create, Update and View
    _hasCrewCreatePermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasCrewCreatePermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _hasCrewUpdatePermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasCrewUpdatePermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _hasCrewViewPermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasCrewViewPermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _hasDeviceSettingsManagementPermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasDeviceSettingsManagementPermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _hasReportsXManagementPermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasReportsXManagementPermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _hasReportsXViewPermission = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));

        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.hasReportsXViewPermission(id);

            if (_perm) permission = _perm;
            return false;
        });

        return permission;
    };

    _isSupervisorUser = () => {
        let groupIDs = this.props.user.groups.map(elem => (elem.id));
        let permission = false;

        groupIDs.map(id => {
            let _perm = Groups.isSupervisorUser(id);

            if (_perm) permission = _perm;
            return false;
        });
        return permission;
    };

    render() {
        return (
            <Sider theme="light" collapsible collapsed={this.state.collapsed} width="250"
                   collapsedWidth={0}
                   onCollapse={this.onCollapse}>
                {!this.state.collapsed &&
                <>
                    <Row className="user_info">
                        <Col>
                            <p>{this.props.user.fullName} ({this.props.user.username})</p>
                            {this.props.user.email && <p>{this.props.user.email}</p>}
                        </Col>
                    </Row>
                    <Divider className="nav_divider"/>
                </>
                }

                <Menu
                    theme="light"
                    mode="inline"
                    // defaultSelectedKeys={[PATHS.getKeyOfPath(window.location.pathname)]}
                    // defaultOpenKeys={[PATHS.getKeyOfSubMenu(window.location.pathname)]}
                >
                    <Menu.Item key={PATHS.getKeyOfPath(PATHS.DASHBOARD)}>
                        <Link className="grey-text text-darken-2" to={PATHS.DASHBOARD}>
                            <HomeOutlined/>
                            <span>Home</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item key={PATHS.getKeyOfPath(PATHS.LOGOUT)}>
                        <Link className="grey-text text-darken-2" to={PATHS.LOGOUT}>
                            <LogoutOutlined/>
                            <span>Logout</span>
                        </Link>
                    </Menu.Item>

                    {
                        (this.state.hasUserCreatePermission || this.state.hasUserUpdatePermission || this.state.hasUserViewPermission) &&
                        <SubMenu
                            key={PATHS.getKeyOfSubMenu(PATHS.SYSTEM_ADD_USER)}
                            title={
                                <span>
                                    <UsergroupAddOutlined/>
                                    <span className="grey-text text-darken-2">Users</span>
                                </span>
                            }
                            className="grey-text text-darken-2"
                        >
                            {this.state.hasUserCreatePermission &&
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.SYSTEM_ADD_USER)}>
                                <Link className="grey-text text-darken-2"
                                      to={PATHS.SYSTEM_ADD_USER}>
                                    <UserAddOutlined/>
                                    <span>Add User</span>
                                </Link>
                            </Menu.Item>
                            }

                            {this.state.hasUserViewPermission &&
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.SYSTEM_VIEW_USER)}>
                                <Link className="grey-text text-darken-2"
                                      to={PATHS.SYSTEM_MANAGE_USERS}>
                                <UserOutlined/>
                                <span>Manage Users</span>
                                </Link>
                            </Menu.Item>
                            }
                        </SubMenu>
                    }

                    {
                        (this.state.hasCrewCreatePermission || this.state.hasCrewUpdatePermission || this.state.hasCrewViewPermission) &&
                        <SubMenu
                            key={PATHS.getKeyOfSubMenu(PATHS.ENTITY_ADD_CREW)}
                            title={
                                <span>
                                    <ContactsOutlined/>
                                    <span className="grey-text text-darken-2">Crews</span>
                                </span>
                            }
                            className="grey-text text-darken-2"
                        >
                            {this.state.hasCrewCreatePermission &&
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.ENTITY_ADD_CREW)}>
                                <Link className="grey-text text-darken-2"
                                      to={PATHS.ENTITY_ADD_CREW}>
                                    <PlusOutlined/>
                                    <span>Add Crew</span>
                                </Link>
                            </Menu.Item>
                            }

                            {this.state.hasCrewViewPermission &&
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.ENTITY_MANAGE_CREWS)}>
                                {/* TODO:// Update this and enable manage crews screen after fixing errors */}
                                {/*<Link className="grey-text text-darken-2"*/}
                                {/*      to={PATHS.ENTITY_MANAGE_CREWS}>*/}
                                {/*    <UserOutlined/>*/}
                                {/*    <span>Manage Crews</span>*/}
                                {/*</Link>*/}
                                <UserOutlined/>
                                <span>Manage Crews</span>
                            </Menu.Item>
                            }
                        </SubMenu>
                    }

                    {/*
                        This nav menu is hidden as work log report is not working!
                    */}
                    {/*<SubMenu*/}
                    {/*    key={PATHS.getKeyOfSubMenu(PATHS.REPORTS)}*/}
                    {/*    title={*/}
                    {/*        <span>*/}
                    {/*                <ProfileOutlined/>*/}
                    {/*                <span className="grey-text text-darken-2">Reports</span>*/}
                    {/*            </span>*/}
                    {/*    }*/}
                    {/*    className="grey-text text-darken-2"*/}
                    {/*>*/}
                    {/*    <Menu.Item key={PATHS.getKeyOfPath(PATHS.REPORTS_WORK_LOG)}>*/}
                    {/*        <Link className="grey-text text-darken-2"*/}
                    {/*              to={PATHS.REPORTS_WORK_LOG}>*/}
                    {/*            <FileTextOutlined/>*/}
                    {/*            <span>Work Log</span>*/}
                    {/*        </Link>*/}
                    {/*    </Menu.Item>*/}
                    {/*</SubMenu>*/}

                    {
                        this.state.hasDeviceSettingsManagementPermission &&
                        <SubMenu
                            key={PATHS.getKeyOfSubMenu(PATHS.ENTITY_ADD_DEVICE_SETTINGS)}
                            title={
                                <span>
                                    <TabletOutlined/>
                                    <span className="grey-text text-darken-2">Manage Devices</span>
                                </span>
                            }
                            className="grey-text text-darken-2"
                        >
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.ENTITY_ADD_DEVICES)}>
                                <Link className="grey-text text-darken-2"
                                      to={PATHS.ENTITY_ADD_DEVICES}>
                                    <PlusOutlined/>
                                    <span>Add Devices</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.ENTITY_VIEW_DEVICES)}>
                                <Link className="grey-text text-darken-2"
                                      to={PATHS.ENTITY_VIEW_DEVICES}>
                                    <TabletOutlined/>
                                    <span>View Devices</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.ENTITY_ADD_DEVICE_SETTINGS)}>
                                <Link className="grey-text text-darken-2"
                                      to={PATHS.ENTITY_ADD_DEVICE_SETTINGS}>
                                    <PlusOutlined/>
                                    <span>Add Settings</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={PATHS.getKeyOfPath(PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES)}>
                                <Link className="grey-text text-darken-2"
                                      to={PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES}>
                                    <SettingOutlined/>
                                    <span>View Config Profiles</span>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                    }

                    {(this.state.hasReportsXManagementPermission || this.state.hasReportsXViewPermission) &&
                    <SubMenu
                        key={PATHS.getKeyOfSubMenu(PATHS.REPORTSX_REPORT)}
                        title={
                            <span>
                                <ProfileOutlined/>
                                <span className="grey-text text-darken-2">Reports X</span>
                            </span>
                        }
                        className="grey-text text-darken-2"
                    >
                        {
                            this.state.hasReportsXManagementPermission &&
                            <>
                                <Menu.Item key={PATHS.getKeyOfPath(PATHS.REPORTSX_MANAGE_USER_ACCESS)}>
                                    <Link className="grey-text text-darken-2"
                                          to={PATHS.REPORTSX_MANAGE_USER_ACCESS}>
                                        <AimOutlined/>
                                        <span title="Manage User Access">Manage User Access</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={PATHS.getKeyOfPath(PATHS.REPORTSX_MANAGE_SUPERVISORS)}>
                                    <Link className="grey-text text-darken-2"
                                          to={PATHS.REPORTSX_MANAGE_SUPERVISORS}>
                                        <AimOutlined/>
                                        <span title="Manage Supervisors">Manage Supervisors</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={PATHS.getKeyOfPath(PATHS.REPORTSX_MANAGE_WORK_DETAILS_ACCESS)}>
                                    <Link className="grey-text text-darken-2"
                                          title="Manage Work Details Access"
                                          to={PATHS.REPORTSX_MANAGE_WORK_DETAILS_ACCESS}>
                                        <AimOutlined/>
                                        <span title="Manage Work Details Access">Manage Work Details Access</span>
                                    </Link>
                                </Menu.Item>
                            </>
                        }

                        {this.state.isSupervisorUser &&
                        <>
                            <Menu.Item key={`${PATHS.getKeyOfPath(PATHS.REPORTSX_SUPERVISOR_MANAGE_IWL)}`}>
                                <Link className="grey-text text-darken-2"
                                      title="Manage Ignore Worker List"
                                      to={PATHS.REPORTSX_SUPERVISOR_MANAGE_IWL}>
                                    <ProfileOutlined/>
                                    <span>Manage IWL</span>
                                </Link>
                            </Menu.Item>
                        </>
                        }

                        {(this.state.hasReportsXViewPermission && this.state.reportsData != null) &&
                        <>
                            {this.state.reportsData.reports.map(r => (
                                <Menu.Item key={`${PATHS.getKeyOfPath(PATHS.REPORTSX_MANAGE_USER_ACCESS)}-${r.id}`}>
                                    <Link className="grey-text text-darken-2"
                                          title={r.name}
                                          to={PATHS.generateDynamicPath(PATHS.DYNAMIC_REPORTSX_REPORT, [r.key])}>
                                        <ProfileOutlined/>
                                        <span>{r.name}</span>
                                    </Link>
                                </Menu.Item>
                            ))}
                        </>
                        }

                    </SubMenu>
                    }
                </Menu>
            </Sider>
        );
    }

}


function mapStateToProps({user, routes}) {
    const authenticated = user.username !== null;
    return {
        user, routes, authenticated
    }
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation);
