import React, {Component} from "react";
import {Route, Routes} from 'react-router-dom';
import {connect} from "react-redux";
import PrivateRoute from "./PrivateRoute";
import PATHS from "../helpers/paths";
import {withRouter} from "../helpers/router";


const ErrorPage = React.lazy(() => import("./common/ErrorPage"));

const DashboardPage = React.lazy(() => import("./Dashboard"));

const LoginPage = React.lazy(() => import("./Login"));
const LogoutPage = React.lazy(() => import("./Logout"));

const SystemAddUserPage = React.lazy(() => import("./system/AddUser"));
const SystemManageUsersPage = React.lazy(() => import("./system/ManageUsers"));
const EntityAddCrewPage = React.lazy(() => import("./entity/AddCrew"));
const EntityManageCrewsPage = React.lazy(() => import("./entity/ManageCrews"));


const EntityAddAccountPage = React.lazy(() => import("./entity/AddAccount"));

const WorkLogReportPage = React.lazy(() => import("./reports/WorkLog"));

const ReportsXManageUserAccess = React.lazy(() => import("./ReportsX/ManageUserAccess"));
const ReportsXManageWorkDetailsAccess = React.lazy(() => import("./ReportsX/ManageWorkDetailsAccess"));
const ReportsXManageSupervisors = React.lazy(() => import("./ReportsX/ManageSupervisors"));
const ReportsXSupervisorManageIWL = React.lazy(() => import("./ReportsX/supervisor/ManageIWL"));
const ReportsXReportsPage = React.lazy(() => import("./ReportsX/reports/ReportsHandler"));

const EntityAddDevicesPage = React.lazy(() => import("./entity/device/AddDevices"));
const EntityViewDevicesPage = React.lazy(() => import("./entity/device/ViewDevices"));
const EntityAddDeviceSettingsPage = React.lazy(() => import("./entity/device/AddSettings"));
const EntityViewDeviceSettingsPage = React.lazy(() => import("./entity/device/ViewDeviceSettings"));
const EntityViewDeviceSettingsHistoryPage = React.lazy(() => import("./entity/device/ViewDeviceSettingsHistory"));
const EntityEditSettingsPage = React.lazy(() => import("./entity/device/EditSettings"));
const EntityViewConfigurationProfilesPage = React.lazy(() => import("./entity/device/ViewConfigurationProfiles"));

class Pages extends Component {
    render() {
        return (
            <div style={{padding: '3rem'}}>
                <Routes>
                    <Route element={<PrivateRoute/>}>

                        {/* ================ Logout page ================*/}
                        <Route
                            exact
                            path={PATHS.LOGOUT}
                            element={<LogoutPage/>}
                        />

                        {/* ================ Dashboard page ================*/}
                        <Route
                            exact
                            path={PATHS.DASHBOARD}
                            element={<DashboardPage/>}
                        />

                        {/* ================ System add user page ================*/}
                        <Route
                            exact
                            path={PATHS.SYSTEM_ADD_USER}
                            element={<SystemAddUserPage/>}
                        />
                        {/* ================ System manage users page ================*/}
                        <Route
                            exact
                            path={PATHS.SYSTEM_MANAGE_USERS}
                            element={<SystemManageUsersPage/>}
                        />

                        {/* ================ System add account page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_ADD_ACCOUNT}
                            element={<EntityAddAccountPage/>}
                        />

                        {/* ================ Entity add crew page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_ADD_CREW}
                            element={<EntityAddCrewPage/>}
                        />

                        {/* ================ Entity manage crews page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_MANAGE_CREWS}
                            element={<EntityManageCrewsPage/>}
                        />

                        {/* ================ Entity add devices page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_ADD_DEVICES}
                            component={EntityAddDevicesPage}
                            element={<EntityAddDevicesPage/>}
                        />

                        {/* ================ Entity view devices page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_VIEW_DEVICES}
                            element={<EntityViewDevicesPage/>}
                        />

                        {/* ================ Entity add device settings page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_ADD_DEVICE_SETTINGS}
                            element={<EntityAddDeviceSettingsPage/>}
                        />

                        {/* ================ Entity view device settings by SID page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_VIEW_DEVICE_SETTINGS}
                            element={<EntityViewDeviceSettingsPage/>}
                        />

                        {/* ================ Entity view device settings history by SID page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_VIEW_DEVICE_SETTINGS_HISTORY}
                            element={<EntityViewDeviceSettingsHistoryPage/>}
                        />

                        {/* ================ Entity view device settings history by SID/setting by setting id page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_VIEW_DEVICE_SETTINGS_HISTORY_BY_ID}
                            element={<EntityViewDeviceSettingsHistoryPage/>}
                        />

                        {/* ================ Entity edit device settings by SID page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_EDIT_DEVICE_SETTINGS}
                            element={<EntityEditSettingsPage/>}
                        />

                        {/* ================ Entity edit settings by config id page ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_EDIT_CONFIG_SETTINGS}
                            element={<EntityEditSettingsPage/>}
                        />

                        {/* ================ Entity view device settings configuration profiles ================*/}
                        <Route
                            exact
                            path={PATHS.ENTITY_VIEW_CONFIGURATION_PROFILES}
                            element={<EntityViewConfigurationProfilesPage/>}
                        />

                        {/* ================ Reports : Work Log page ================*/}
                        <Route
                            exact
                            path={PATHS.REPORTS_WORK_LOG}
                            element={<WorkLogReportPage/>}
                        />

                        {/* ================ ReportsX : Manage User Access ================*/}

                        <Route
                            exact
                            path={PATHS.REPORTSX_MANAGE_USER_ACCESS}
                            element={<ReportsXManageUserAccess/>}
                        />
                        {/* ================ ReportsX : Manage Supervisors ================*/}
                        <Route
                            exact
                            path={PATHS.REPORTSX_MANAGE_SUPERVISORS}
                            element={<ReportsXManageSupervisors/>}
                        />

                        {/* ================ ReportsX : Manage User Access ================*/}
                        <Route
                            exact
                            path={PATHS.REPORTSX_MANAGE_WORK_DETAILS_ACCESS}
                            element={<ReportsXManageWorkDetailsAccess/>}
                        />

                        {/* ================ ReportsX : Supervisor Manage Ignore Worker List ================*/}
                        <Route
                            exact
                            path={PATHS.REPORTSX_SUPERVISOR_MANAGE_IWL}
                            element={<ReportsXSupervisorManageIWL/>}
                        />

                        {/* ================ ReportsX Report by key page ================*/}
                        <Route
                            exact
                            path={PATHS.REPORTSX_REPORT}
                            element={<ReportsXReportsPage/>}
                        />
                    </Route>

                    <Route
                        exact
                        path={PATHS.HOME}
                        element={<LoginPage/>}/>

                    <Route
                        exact
                        path={PATHS.LOGIN}
                        element={<LoginPage/>}/>

                    <Route
                        path="*"
                        element={<ErrorPage/>}/>

                </Routes>
            </div>
        );
    }
}


function mapStateToProps({user, routes}) {

    return {
        user, routes
    }
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
