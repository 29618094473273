import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from './reducers'

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['user']
};


const persistedReducer = persistReducer(persistConfig, rootReducer);


function configureStore(persistedReducer) {

    const DEV = (process.env.NODE_ENV !== "production");

    const middlewares = [thunkMiddleware, DEV && logger].filter(Boolean);


    const enhancer = compose(
        applyMiddleware(...middlewares),
    );
    return createStore(persistedReducer, undefined, enhancer);
}


export const store = configureStore(persistedReducer);
export const persistor = persistStore(store);
