import createReducer from '../lib/createReducer';
import {SET_ROUTES_STATE} from '../actions/routes';


export const routes = createReducer({}, {

    [SET_ROUTES_STATE](state, action) {
        let newState = Object.assign({}, state);
        newState = action.data;
        return newState;
    }
});